@import '../theme-bootstrap';

$bk-border-color: rgb(199, 195, 195);
$bk-color-black: #000000;
$bk-color-green: rgb(112, 176, 0);
$bk-warning: #ee9f57;

[data-component='cart-sidebar'] {
  font-family: $font--text;
}
.elc-overlay-sidebar.elc-overlay-sidebar-slidein {
  width: 100%;
  @include breakpoint($medium-up) {
    width: auto;
  }
}

[data-rendered-view='cart-sidebar-cart-offers'],
[data-rendered-view='cart-sidebar-offers-tab'] {
  width: 100%;
}

.elc-cart-empty-content {
  color: $color-almost-black;
}

.elc-cart-sidebar {
  &-title {
    @include font-face--helvetica-medium;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    p.elc-subtitle--2 {
      color: $color-almost-black;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1.5px;
      text-align: center;
      margin-top: 3px;
    }
  }

  div &-close {
    width: auto;
    padding-top: 0;
    padding-#{$rdirection}: 23px;
    @include breakpoint($medium-up) {
      padding-#{$rdirection}: 18px;
    }
    &-icon {
      .elc-icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-counter {
    color: $color-almost-black;
    font-size: 10px;
  }

  &-header {
    flex-direction: row-reverse;
    height: 48px;
  }

  &-go-shopping-button.elc-button {
    @include font-face--helvetica-roman;
    color: $color-white;
    font-size: 12px;
    letter-spacing: 1.9px;
    line-height: 24px;
  }

  &-checkout-button.elc-button {
    border-radius: 0;
    p.elc-subtitle--2 {
      @include font-face--helvetica-roman;
      font-size: 12px;
      letter-spacing: 1.9px;
      line-height: 24px;
    }
  }

  &-sub-total {
    color: $color-almost-black;
    p.elc-subtitle--2 {
      line-height: 28px;
    }
    font-size: 16px;
  }
}

.elc-cart-item {
  div &-panel {
    border-bottom: 1px solid $bk-border-color;
  }

  &-remove {
    button {
      font-size: 12px;
      color: $color-black;
      letter-spacing: 0.5px;
      background-color: inherit;
      border: none;
      &:hover,
      &:focus {
        background-color: transparent;
        color: $color-gray;
      }
    }
  }
  &-description {
    &-name p.elc-subtitle--2 {
      @include font-face--helvetica-medium;
    }

    &-sku-description.elc-body--2 {
      @include font-face--helvetica-roman;
      padding-top: 1px;
      line-height: 16px;
    }

    &-link {
      p.elc-subtitle--2 {
        line-height: 24px;
        white-space: normal;
      }
    }

    &-product {
      @include font-face--helvetica-roman;
      color: $color-darkest-gray;
      &-wrapper {
        p.elc-caption {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0;
        }
      }

      &-quantity {
        padding-top: 8px;
        padding-bottom: 8px;

        &-spinner-selector.elc-spinner-selector {
          border-radius: 0;
          .elc-spinner-selector-button {
            border-radius: 0;
            width: 25px;
            height: 25px;
            .elc-spinner-minus-icon.elc-icon,
            .elc-spinner-plus-icon.elc-icon {
              height: 8px;
              width: 8px;
            }
          }

          .elc-spinner-selector {
            border-radius: 0;
            &-data {
              @include font-face--helvetica-roman;
              font-size: 12px;
              &-wrapper {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        p.elc-body--2 {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0;
        }
      }
    }
  }
  &-price-wrapper {
    color: $color-almost-black;
  }
  &-engrave-message {
    color: $bk-color-black;
    font-family: $font--english--regular;
    font-size: 24px;
    p {
      margin: 0;
    }
  }
  &-total-price-wrapper {
    .elc-cart-item-old-total-price {
      display: none;
    }
  }
}
.elc-offer {
  &-header-text.elc-body--2 {
    @include font-face--helvetica-medium;
  }
  &-tout {
    &-title-text.elc-subtitle--1 {
      @include font-face--helvetica-medium;
      letter-spacing: 1.5px;
      line-height: 16px;
      text-transform: uppercase;
    }
    &-title {
      &-text.elc-subtitle--1.cart-tab-offer {
        @include font-face--helvetica-medium;
        letter-spacing: 0.15px;
        line-height: 18px;
        text-transform: none;
      }
    }
    &-title.cart-tab-offer {
      padding-top: 15px;
    }
  }
  &-item {
    &-content {
      &-image-container {
        &-image.offer-image {
          margin-top: 10px;
        }
      }
      &-description {
        &-title.elc-subtitle--1 {
          @include font-face--helvetica-medium;
          letter-spacing: 0;
        }
        &-sub.elc-caption {
          @include font-face--helvetica-medium;
          letter-spacing: 0;
          font-size: 12px;
          width: 75%;

          @include breakpoint($medium-up) {
            width: 100%;
          }
        }
        &-caption p {
          @include font-face--helvetica-roman;
          letter-spacing: 0;
        }
        &-threshold {
          &-text {
            @include font-face--helvetica-roman;
            letter-spacing: 0;
          }
          &-icon.elc-icon {
            background-color: $bk-warning;
            width: 16px;
            height: 16px;
          }
        }
        &-interaction {
          &-button {
            @include font-face--helvetica-medium;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            line-height: 20px;
          }
        }
        &-expiration-date {
          &-sub {
            @include font-face--helvetica-medium;
            height: 16px;
            width: 40px;
            text-align: center;
          }
          &-number {
            @include font-face--helvetica-roman;
            color: $bk-color-black;
            height: 12px;
            width: 40px;
            text-align: center;
          }
        }
      }
    }
  }
}

// Specificity required to override styled components.
.elc-overlay-sidebar.elc-overlay-sidebar {
  .elc-cart-item-image {
    font-size: 12px;
    line-height: 1.5;
    min-height: 97px;
    min-width: auto;
    width: 80px;
  }
  .elc-cart-item-description {
    width: auto;
  }
  .elc-cart-item-details-wrapper,
  .elc-elc-cart-item-details-wrapper {
    @include breakpoint($medium-up) {
      width: 100%;
    }
  }
  .elc-cart-item-percent {
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }
}
